<template>
  <div class="bonuses-wager-progress">
    <div v-if="props.bonusInfo.wagerCasino" class="bonuses-wager-progress__row">
      <atomic-icon id="cherry" class="bonuses-wager-progress__icon" />

      <span class="bonuses-wager-progress__label">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.wager') }}
      </span>

      <span class="bonuses-wager-progress__value"> (x{{ props.bonusInfo.wagerCasino }}): </span>

      <span class="bonuses-wager-progress__amount">
        {{ formatAmount(props.bonusInfo.currency, props.bonusInfo.currentWagerCasinoAmount) }}
      </span>

      <span class="bonuses-wager-progress__divider">/</span>

      <span class="bonuses-wager-progress__amount">{{ fullCasinoAmount }}</span>
    </div>

    <div v-if="props.bonusInfo.wagerSportsbook" class="bonuses-wager-progress__row">
      <atomic-icon id="sportsbook-football" class="bonuses-wager-progress__icon" />

      <span class="bonuses-wager-progress__label">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.wager') }}
      </span>

      <span class="bonuses-wager-progress__value"> (x{{ props.bonusInfo.wagerSportsbook }}): </span>

      <span class="bonuses-wager-progress__amount">
        {{ formatAmount(props.bonusInfo.currency, props.bonusInfo.currentWagerSportsbookAmount) }}
      </span>

      <span class="bonuses-wager-progress__divider">/</span>

      <span class="bonuses-wager-progress__amount">
        {{ fullSportsbookAmount }}
      </span>
    </div>

    <bonuses-progress :progress="props.bonusInfo.currentWagerPercentage" />

    <bonuses-waiting-results
      v-if="props.bonusInfo.currentWagerPercentage === 100 && props.bonusInfo.openedTransactionsCount > 0"
      :text="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.waitingResult')"
    />
  </div>
</template>

<script setup lang="ts">
  import type { IPlayerBonus } from '~/types';

  const props = defineProps<{
    bonusInfo: IPlayerBonus;
  }>();

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();

  const { formatAmount } = useProjectMethods();

  const fullCasinoAmount = computed<string>(() => {
    const fullAmount = props.bonusInfo.currentWagerCasinoAmount + props.bonusInfo.requiredWagerCasinoAmount;
    const roundAmount = Number(fullAmount.toFixed(2));
    return formatAmount(props.bonusInfo.currency, roundAmount);
  });

  const fullSportsbookAmount = computed<string>(() => {
    const fullAmount = props.bonusInfo.currentWagerSportsbookAmount + props.bonusInfo.requiredWagerSportsbookAmount;
    const roundAmount = Number(fullAmount.toFixed(2));
    return formatAmount(props.bonusInfo.currency, roundAmount);
  });
</script>

<style src="~/assets/styles/components/bonuses/wager-progress.scss" lang="scss" />
